/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/pagebuilder';
.CartItem {
    border-color: #676056;
    &-Wrapper {
        background-color: #{$default-background};
    }

    & &-Qty {
        input[type=text] {
            width:auto;
        }
    }
    &-Options {
        color: var(--color-black);
    }

    &-Heading {
        white-space: normal;
        font-size: 14px;
    }
    &-Quantity {
        font-size: 12px;
    }
    .discount {
        margin-top: 10px;
    }
    &-CustomerTradeUnit {
        height: var(--input-type-number-width);
        margin: 10px;
        font-size: 14px;
        text-transform: capitalize;
        @include mobile {
            margin: 0 10px;
        }
        &_isCartOverlay {
            color: black;
            height: auto;
            margin-left: 0;
            text-transform: capitalize;
        }
    }
    &-Qty {
        background: transparent;
    }
}
