/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/pagebuilder';
.CheckoutOrderSummary {
    .CartPage-Discount {
        margin-top: 20px;
        @include mobile {
            border-top: 1px solid #676056;
            border-bottom: 1px solid #676056;
        }
        .CartCoupon {
            padding-bottom: 5px;
            .Field {
                margin-top: 0;
            }
            .CartCoupon-Button {
                margin-left: 0;
                padding: 0px 15px;
                height: 46px;
                &_isHollow {
                    margin-left: 14px;
                }
                @include mobile {
                    margin-top: 0;
                    &_isHollow {
                        margin-left: 0;
                        margin-top: 14px;
                    }
                }
            }           
        }
        .ExpandableContent-Heading {
            @include desktop {
                font-size: 18px;
            }
        }
    }
}
.CartCoupon {
    &-Input {
        margin-top: 0;
    }
}
