/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/pagebuilder';
.CartPage {
  .ContentWrapper.CartPage-Wrapper {
    padding: 0 16px;
    @include mobile {
      padding: 0;
    }
    @include desktop {
      grid-template-columns: 5fr 3fr;
      grid-gap: 7%;
    }
  }
  &-Heading {
    font-size: 48px;
  }
  &-TableHead {
    background-color: #f9e4c5;
  }
  &-Promo {
    order: 3;
    min-height: unset;
  }
  &-Summary {
    order: 1;
    background: #{$default-background};
  }
  &-QuoteBtn {
    order: 2;
    // border: 1px solid #e5e5e5;
    margin-top:0;
  }
  &-CartThresholdFailed {
    font-size: 16px;
    padding: 10px 0;
    color: #d9200d;
    font-weight: 700;
  }
  &-Floating {
    @include desktop {
      margin-top: 0;
    }
  }
  @include desktop {
    &-CheckoutButton {
      border-radius: 10px;
      &.Button:not([disabled]):hover {
        border-radius: 10px;
      }
    }
  }
  &-Discount {
    @include desktop {
      .CartPage-ExpandableContentHeading {
        font-size: 18px;
      }
    }
  }
}
.Checkout {
  &-ProgressSection { 
    margin-top: 20px;
    @include mobile {
      margin-top: 0;
      margin-bottom: 25px;
    }
    .Checkout {
        &-Header {
            width: 100%;
            z-index: 3;
            min-height: 75px;
            padding-top: 10px;
            padding-bottom: 10px;
        }
        &-Step {
            width: 100%;
            &-Label {
                width: 100%;
                display: block;
            }
        }
        &-Step1,
        &-Step2,
        &-Step3,
        &-Step4 {
            width: 25%;
            float: left;
            text-align: center;
            &-Icon {
                .LockIcon {
                    fill: #00499b;
                    margin-right: 0;
                    border: 1px solid #00499b;
                    border-radius: 50%;
                    width: 34px;
                    height: 34px;
                }
            }
        }
        &-IconWrapper {
            background: #fdf5e8;
            width: 50px;
            display: block;
            text-align: center;
            margin: 0 auto;
            &_active {
                .LockIcon {
                    background: #00499b;
                    fill: white;
                }
            }
        }
    }
  }
  &-StepBarTotal {
    position: absolute;
    top: 28px;
    width: 69%;
    left: 0;
    right: 0;
    margin: 0 auto;
    height: 2px;
    background-color: #b0c1c9;
    opacity: 0.75;
  } 
}