/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/pagebuilder';
.CartOverlay {
    @include desktop {
        height: calc(90vh - 200px);
        overflow-y: auto;
    }

    &-Additional {
        padding: 0 16px 0 16px;
        .MiniBasketTotalMessage {
            padding-top: 16px;
            p { margin-bottom: 0; }
        }
    }
    &-Total {
        &-Asterix {
            padding-left: 1rem;
        }
    }
}
